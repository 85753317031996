const ADD = "/add";
const COMFIRM = "/confirm";
const COMPLETE = "/complete";
const EDIT = "/edit";
const FAIL = "/fail";
const PAYMENT = "/payment";
const PAYMENT_OPTION = "/paymentoption";
export const BASE_APP_PATH = "/app/#";

export const SLASH = "/";
export const CREATEPASSWORD = "/createpassword";
export const CHANGEPASSWORD = "/changepassword";
export const FORGOTPASSWORD = "/forgotpassword";
export const SIGNIN = "/login";
export const AGENT_SIGNIN = "/agent";
export const SIGNIN_PASSWORD = "/password";
export const SIGNIN_PATH = BASE_APP_PATH + SIGNIN;

// email verify/MFA
export const EMAIL_VERIFY = "/emailVerify";

// Error page routes
export const FORBIDDEN = "/Is/Forbidden.html";

// Billing Routes
export const BILLING_PAGE = "/billing";
export const BILLING_STATEMENT_IMPORT = BILLING_PAGE + "/statements/import";

// Sign Up Routes
export const SIGNUP = "/signup";
export const SIGNUPDETAILS = SIGNUP + "/details";
export const SIGNUP_PLAN_DETAILS = SIGNUP + "/plans";
export const SIGNUPVERIFY = SIGNUP + "/verify";

export const SIGNUPPASSWORD = SIGNUP + "/password";
export const SIGNUPJOINPASSWORD = SIGNUP + "/join/password";

export const SIGNUPINVITE = SIGNUP + "/invite";
export const SIGNUPJOININVITE = SIGNUP + "/join/invite";

export const SIGNUPJOIN = SIGNUP + "/join";
export const SIGNUPJOINVERIFY = SIGNUP + "/join/verify";
export const SIGNUPJOINDETAILS = SIGNUP + "/join/details";

export const TRAVELLER = "/traveller";
export const TRAVELLER_ADD = TRAVELLER + ADD;
export const TRAVELLER_IMPORT = TRAVELLER + "/import";
export const TRAVELLER_EDIT = EDIT;

export const POLICIES_PAGE = "/policy";
export const POLICIES_PAGE_ADD = POLICIES_PAGE + ADD;
export const POLICIES_PAGE_EDIT = EDIT;

export const TAGS = "/tag";
export const TAGS_ADD = TAGS + ADD;
export const TAGS_EDIT = EDIT;

export const TRIPS = "/trip";
export const VISA = "/visa";

export const TRIPS_ADD = "/add";
export const TRIPS_IMPORT_SERVICE = "/importservices";
export const TRIPS_EDIT = EDIT;
export const TRIPS_ADD_APIS = "/addapis";
export const TRIPS_CANCEL_TRIP = "/cancel";
export const TRIPS_CHANGE_TRIP = "/change";
export const TRIPS_REFUND_TRIP = "/refund";
export const TRIPS_PURCHASE_TRIP = "/purchase";
export const TRIPS_SUBMIT_DOCUMENTS = "/submitdocument";
export const TRIPS_CONSOLE = "/console";

export const TRIPS_CANCEL_BOOKING_TRIP = "/cancelbooking";
export const TRIPS_CHANGE_FLIGHT_BOOKING = "/changeflightbooking";
export const TRIPS_CHANGE_HOTEL_BOOKING = "/changehotelbooking";

export const TRIPS_ADD_BOOKING = "/addbooking";
export const TRIPS_ADD_SERVICE = "/addservice";
export const TRIPS_ADD_PAYMENT = "/addpayment";
export const TRIPS_ADD_MULTIPLE_SERVICE = "/addmultipleservices";
export const TRIPS_IMPORT_SERVICES =
  "/Plus/Trip/Service/BulkImportTripsAndServices.aspx?Iframe=true";

export const TRIPS_EMAIL_TICKET = "/emailticket";
export const TRIPS_EMAIL_CANCEL_REQUEST = "/emailcancelrequest";
export const TRIPS_EMAIL_CHANGE_REQUEST = "/emailchangerequest";
export const TRIPS_EMAIL_RECEIPT = "/emailreceipt";
export const TRIPS_EMAIL_PURCHASE_REQUEST = "/emailpurchaserequest";

export const TRIPS_APPROVALS_INITIALIZATION = "/initiate";

// Approval Routes
export const APPROVALS_PAGE = "/approval";
export const APPROVALS_REJECT = "/reject";
export const APPROVALS_APPROVE = "/approve";
export const BOOKING_CANCELLATION_REVIEW = "/review";
export const BOOKING_CANCELLATION_ESIMATE_REFUND = "/estimaterefund";
export const BOOKING_CANCELLATION_VERIFY = "/verify";
export const BOOKING_CANCELLATION_COMPLETE = "/complete";
export const FLIGHT_IMAGE_PATH = "/Resource/Image/Logo/Airline/Small/";

// My Account Routes
export const MY_ACCOUNT_PAGE = "/myaccount";
export const COMPANY_PAGE = "companydetails";
export const PAYMENTS_PAGE = "plans-payment";
export const PAYMENT_ID = "/paymentId";
export const CHANGE_PASSWORD_PAGE = "changepassword";

export const CHANGE_PLAN = "/changeplan";
export const RENEW_PLAN = "/renew-plans";
export const CHANGE_OR_RENEW_PLAN_DETAILS = "/plandetails";
export const CHANGE_PLAN_PAYMENT = PAYMENT;
export const CHANGE_PLAN_PAYMENT_OPTION = PAYMENT + PAYMENT_OPTION;
export const CHANGE_PLAN_CONFIRM = COMFIRM;
export const CHANGE_PLAN_COMPLETE = COMPLETE;
export const RENEW_PLAN_COMPLETE = COMPLETE;
export const CHANGE_PLAN_FAIL = FAIL;
export const RENEW_PLAN_FAIL = FAIL;

export const PLAN_PAY_NOW = PAYMENT;
export const PAY_PAYMENT_OPTION = PAYMENT_OPTION;
export const PAY_CONFRIM = COMFIRM;
export const PAY_COMPLETE = COMPLETE;
export const PAY_FAIL = FAIL;

export const CONVERSATION = "/conversation";

export const INVOICE_DETAILS = "/invoice-details";

export const SUSPENDED_ACCOUNT = "/suspended-account";

export const PLUS_DASHBOARD = "/Plus/Default.aspx";
export const MY_DASHBOARD = "/My/Default.aspx";
export const LOGOUT = "/Plus/SignOut.aspx";
export const HOTEL_CHECKOUT = "/Trip/Checkout.aspx?spid=";

export const SIGNUP_PLAN_DESCRIPTION = "https://business.musafir.com/pricing";
export const SIGNUP_PLAN_TERMS = "https://business.musafir.com/terms";
export const PAYMENT_REGISTER_PAGE = "/Payment/Register.aspx";

// Components Routes
export const COMPONENTS_PAGE = "/components";
export const TIMELINE_PAGE = "timeline";
export const TABS_PAGE = "tabs";
export const CANCELLATION_COMPONENTS_PAGE = "cancellationComponents";
export const DRAWER_PAGE = "drawer";
export const APPROVAL_DONATION_PAGE = "donation";
export const APPROVAL_TRAVELER_PAGE = "approval_traveler";
export const CHANGE_TRIP_PAGE = "changeTrip";
export const STEPPER_PAGE = "stepper";
export const TRIP_COMPONENTS_PAGE = "tripcomponents";
export const HOTEL_LIST_COMPONENTS_PAGE = "hotellistcomponent";
export const HOTEL_PACKAGE_LIST_COMPONENTS_PAGE = "hotelpackagelistcomponent";
export const TRIP_SERVICE_BLOCK = "tripserviceblock";

// static routes
export const NO_BROWSER_SUPPORT = "/nobrowsersupport";
export const NO_SCRIPT_SUPPORT = "/noscriptsupport";

// change trip routes
export const CHANGE_TRIP_REVIEW = "/review";
export const CHANGE_TRIP_REQUEST = "/request";
export const CHANGE_TRIP_COMPLETE = "/complete";

// hotel routes
export const HOTEL = "/hotel";
export const HOTEL_SEARCH = "/search";

// hotel routes
export const FLIGHT = "/flight";
export const FLIGHT_SEARCH = "/search";

// request form
export const REQUEST_FORM = "/requestform";
export const REQUEST_FORM_ADD = ADD;
export const REQUEST_FORM_ADD_TRIP = "/addtrip";
export const REQUEST_FORM_EDIT = EDIT;

// expense management routes
export const EXPENSE_MANAGEMENT = "/expense";
export const EXPENSE_REQUEST_FOLIO_FORM = "/requestfolio";
export const EXPENSE_APPROVAL = "/approval";
export const EXPENSE_REQUEST_FOLIO =
  EXPENSE_MANAGEMENT + EXPENSE_REQUEST_FOLIO_FORM;
export const EXPENSE_REQUEST_FOLIO_ADD =
  EXPENSE_MANAGEMENT + EXPENSE_REQUEST_FOLIO_FORM + ADD;
export const EXPENSE_REQUEST_NEW_FOLIO = EXPENSE_REQUEST_FOLIO_FORM + ADD;
export const EXPENSE_FORM_EDIT = EDIT;
export const EXPENSE_APPROVAL_FORM = EXPENSE_MANAGEMENT + EXPENSE_APPROVAL;
export const EXPENSE_APPROVAL_ADD = EXPENSE_MANAGEMENT + EXPENSE_APPROVAL + ADD;

export const SEATMAP = "/seatMap";

export const EMAILVERIFY = "/emailverify";

export const QUOTATION = "/quotation";


